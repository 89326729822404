<template>
    <div class="supplier">
        <div class="supplier-name">
          <h3>{{supplier.name}}</h3>
        </div>
      
        <div class="actions">
            <img @click="handle_delete(supplier.name)" src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/icons%2Ftrash-bin_375x375.png?alt=media&token=343ab5d5-380b-4c00-9c6d-ca5152b4673b" alt="">
            <img @click="update_supplier(supplier.name)" src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/icons%2Fpencil%20(1)_375x375.png?alt=media&token=78a64ec1-c7b4-49d0-ba40-963c4db41bed" alt="">
        </div>    
    </div>
  </template>
  
  <script>
  import {alert_confirm} from '../../../../Methods/Msgs'
  import {projectFirestore} from '../../../../firebase/config'
  import Swal from 'sweetalert2'
  export default {
      props:['supplier'],
      emits:['delete','modify'],
      setup(props,{emit}){
          const update_supplier = async (uid) => {
              Swal.fire({
                  icon: 'question',
                  title: `עדכון פרטי ספק - ${uid}`,
                  html: `
                      <input id="supplierName" value="${props.supplier.name.replace(/"/g, '&quot;')}" type="text" class="swal2-input" placeholder="שם ספק">
                      <input id="supplierNum" value=${props.supplier.supply_num} type="text" class="swal2-input" placeholder="מס ספק">
                  `,
                  confirmButtonText: 'עדכן',
                  cancelButtonText: 'בטל',
                  showCancelButton: true,
                  preConfirm: () => {
                      const supplierName = document.getElementById('supplierName').value.trim();
                      const supplierNum = document.getElementById('supplierNum').value.trim();
  
                      if (!supplierName || !supplierNum) {
                          Swal.showValidationMessage('כל השדות חייבים להיות מלאים');
                          return null;
                      }
                      return { supplierName, supplierNum };
                  }
              }).then(async (res) => {
                  if (res.isConfirmed && res.value) {
                      const { supplierName, supplierNum } = res.value;
                      await handle_modify_supplier(uid, { supplierName, supplierNum });
                  }
              });
          };
  
          const handle_delete = async(supplier_name)=>{
              alert_confirm(`האם למחוק את ספק ${supplier_name}`)
              .then(async(res)=>{
                  if(res.isConfirmed){
                      await projectFirestore.collection('Shivuk')
                      .doc('Shivuk').collection('Suppliers').doc(supplier_name)
                      .delete()
                      emit('delete',supplier_name)
                  }
              })
  
          }
          
          const handle_modify_supplier = async (old_supplier_name, { supplierName, supplierNum }) => {
              console.log(old_supplier_name);
              // Delete the old branch document
              await projectFirestore.collection('Shivuk')
                  .doc('Shivuk')
                  .collection('Suppliers')
                  .doc(old_supplier_name)
                  .delete();
  
              // Create a new branch document with the updated information
              await projectFirestore.collection('Shivuk')
                  .doc('Shivuk')
                  .collection('Suppliers')
                  .doc(supplierName) // Using supplierNum as the new document ID
                  .set({
                      name: supplierName,
                      supply_num: supplierNum // Include the branch number in the document
                  });
  
              // Emit an event with the updated branch details
              emit('modify', {
                  old_supplier_name,
                  new_supplier_name: supplierName, // Use the new branch number as identifier
                  supplierNum
              });
          };
  
          return{handle_delete,handle_modify_supplier,update_supplier}
      }
  }
  </script>
  
  <style scoped>
      .supplier{
          background: #fff;
          width: 100%;
          height: 100%;
          -webkit-box-shadow: 5px 5px 10px 5px rgba(0,0,0,0.15); 
          box-shadow: 5px 5px 10px 5px rgba(0,0,0,0.15);
          border-radius: 10px;
          display: flex;
      }
      .supplier-name{
          width: 60%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
      }
      .actions{
          width: 40%;
          height: 100%;
          display: flex;
          justify-content: space-around;
          align-items: center;
      }
      img{
          cursor: pointer;
          width: 32px;
          height: 32px;
      }
  </style>